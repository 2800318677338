define("discourse/plugins/swapd-support-tickets/discourse/components/modal/send-invite-modal", ["exports", "@ember/component", "I18n", "@ember/object", "discourse/lib/utilities", "@glimmer/tracking", "discourse/lib/ajax", "@ember/application", "@ember/template", "@ember/template-factory"], function (_exports, _component, _I18n, _object, _utilities, _tracking, _ajax, _application, _template, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    class="show-invite-modal"
    id="show-invite-modal"
    @flash={{this.flash}}
    @title={{i18n "support_tickets.start.send_invite_title"}}
  >
  <:body>
    <p>{{ this.msg}}</p>
  <Input
      placeholder={{i18n "support_tickets.start.enter_email"}}
      @value={{this.value}} />
  </:body>
    <:footer>
      <DButton
          @action={{action "inviteUser"}}
          @class="btn-danger btn-large invite"
          @label={{"support_tickets.start.send_invite_invite_button"}}
          @disabled={{this.btnDisabled}}
      />
      <DButton
          @action={{@closeModal}}
          @class="btn-large"
          @label={{"support_tickets.start.send_invite_cancel_button"}}
      />
    </:footer>
  
  </DModal>
  */
  {
    "id": "7CDZbRfn",
    "block": "[[[8,[39,0],[[24,0,\"show-invite-modal\"],[24,1,\"show-invite-modal\"]],[[\"@flash\",\"@title\"],[[30,0,[\"flash\"]],[28,[37,1],[\"support_tickets.start.send_invite_title\"],null]]],[[\"body\",\"footer\"],[[[[1,\"\\n  \"],[10,2],[12],[1,[30,0,[\"msg\"]]],[13],[1,\"\\n\"],[8,[39,2],[[16,\"placeholder\",[28,[37,1],[\"support_tickets.start.enter_email\"],null]]],[[\"@value\"],[[30,0,[\"value\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"\\n    \"],[8,[39,3],null,[[\"@action\",\"@class\",\"@label\",\"@disabled\"],[[28,[37,4],[[30,0],\"inviteUser\"],null],\"btn-danger btn-large invite\",\"support_tickets.start.send_invite_invite_button\",[30,0,[\"btnDisabled\"]]]],null],[1,\"\\n    \"],[8,[39,3],null,[[\"@action\",\"@class\",\"@label\"],[[30,1],\"btn-large\",\"support_tickets.start.send_invite_cancel_button\"]],null],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\"],false,[\"d-modal\",\"i18n\",\"input\",\"d-button\",\"action\"]]",
    "moduleName": "discourse/plugins/swapd-support-tickets/discourse/components/modal/send-invite-modal.hbs",
    "isStrictMode": false
  });
  let SendInviteModal = _exports.default = (_class = class SendInviteModal extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "flash", _descriptor, this);
      _defineProperty(this, "value", "");
      _initializerDefineProperty(this, "btnDisabled", _descriptor2, this);
      _defineProperty(this, "msg", "");
    }
    init() {
      super.init(...arguments);
      var key = this.userNotFound ? "user_not_found" : "send_invite_msg";
      this.msg = _I18n.default.t(`support_tickets.start.${key}`);
    }
    showError(err) {
      this.flash = err ? err : "An error has occurred";
    }
    inviteUser() {
      if (!(0, _utilities.emailValid)(this.value)) {
        this.showError("Invalid email");
        return false;
      } else {
        this.flash = null;
      }
      this.btnDisabled = true;
      (0, _ajax.ajax)("/invites", {
        type: "POST",
        data: {
          email: this.value
        }
      }).then(result => {
        if (result.invite_key) {
          this.closeModal();
          const dialog = (0, _application.getOwner)(this).lookup("service:dialog");
          dialog.alert({
            message: (0, _template.htmlSafe)(_I18n.default.t("support_tickets.start.invite_sent"))
          });
        } else {
          this.showError();
        }
      }).catch(e => this.showError(e?.jqXHR?.responseJSON?.errors[0])).finally(() => this.btnDisabled = false);
      return true;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "flash", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "btnDisabled", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "inviteUser", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "inviteUser"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, SendInviteModal);
});