define("discourse/plugins/swapd-support-tickets/discourse/routes/user-activity-report", ["exports", "discourse/routes/discourse", "discourse/lib/ajax"], function (_exports, _discourse, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    titleToken() {
      return I18n.t("support_tickets.report_common.page_title");
    },
    model() {
      const user = this.modelFor("user");
      return (0, _ajax.ajax)(`/report/u/${user.id}`, {
        type: "POST"
      }).then(r => {
        return {
          ...r,
          user
        };
      });
    }
  });
});