define("discourse/plugins/swapd-support-tickets/discourse/controllers/start", ["exports", "discourse-common/utils/decorators", "@ember/string", "@ember/controller"], function (_exports, _decorators, _string, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _controller.default.extend((_dec = (0, _decorators.default)("step"), (_obj = {
    step: 0,
    topic: null,
    componentName(step) {
      const steps = (0, _string.w)("zero one two advanced");
      if (step < 3) {
        return "step-" + steps[step];
      }
      return "step-advanced";
    }
  }, (_applyDecoratedDescriptor(_obj, "componentName", [_dec], Object.getOwnPropertyDescriptor(_obj, "componentName"), _obj)), _obj)));
});