define("discourse/plugins/swapd-support-tickets/discourse/components/modal/rank-edit-modifiers", ["exports", "@ember/component", "discourse/lib/ajax", "@ember/object", "@ember/template-factory"], function (_exports, _component, _ajax, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    class="rank-edit-modifiers"
    id="rank-edit-modifiers"
    @closeModal={{@closeModal}}
    @title={{i18n "support_tickets.user_report.edit_modifiers"}}
  >
    <:body>
      <section>
        <label>
          {{i18n "support_tickets.user_report.sales_label"}}
        </label>
        <Input type="text" @value={{this.model.sales}} />
      </section>
      <section>
        <label>
          {{i18n "support_tickets.user_report.purchases_label"}}
        </label>
        <Input type="text" @value={{this.model.purchases}} />
      </section>
    </:body>
    <:footer>
      <DButton
        @class="btn-danger btn-large"
        @action={{action "save"}}
        @label={{"support_tickets.user_report.save"}}
      />
    </:footer>
  </DModal>
  */
  {
    "id": "GxX4R+dh",
    "block": "[[[8,[39,0],[[24,0,\"rank-edit-modifiers\"],[24,1,\"rank-edit-modifiers\"]],[[\"@closeModal\",\"@title\"],[[30,1],[28,[37,1],[\"support_tickets.user_report.edit_modifiers\"],null]]],[[\"body\",\"footer\"],[[[[1,\"\\n    \"],[10,\"section\"],[12],[1,\"\\n      \"],[10,\"label\"],[12],[1,\"\\n        \"],[1,[28,[35,1],[\"support_tickets.user_report.sales_label\"],null]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[8,[39,2],[[24,4,\"text\"]],[[\"@value\"],[[30,0,[\"model\",\"sales\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"section\"],[12],[1,\"\\n      \"],[10,\"label\"],[12],[1,\"\\n        \"],[1,[28,[35,1],[\"support_tickets.user_report.purchases_label\"],null]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[8,[39,2],[[24,4,\"text\"]],[[\"@value\"],[[30,0,[\"model\",\"purchases\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,3],null,[[\"@class\",\"@action\",\"@label\"],[\"btn-danger btn-large\",[28,[37,4],[[30,0],\"save\"],null],\"support_tickets.user_report.save\"]],null],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\"],false,[\"d-modal\",\"i18n\",\"input\",\"d-button\",\"action\"]]",
    "moduleName": "discourse/plugins/swapd-support-tickets/discourse/components/modal/rank-edit-modifiers.hbs",
    "isStrictMode": false
  });
  let RankEditModifiers = _exports.default = (_class = class RankEditModifiers extends _component.default {
    save() {
      (0, _ajax.ajax)(`/report/u/${this.model.userId}/modifier`, {
        type: "POST",
        data: {
          purchases: parseInt(this.model.purchases),
          sales: parseInt(this.model.sales)
        }
      }).then(r => {
        this.model.updateSales(this.model.sales);
        this.model.updatePurchases(this.model.purchases);
        this.closeModal();
      });
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "save", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, RankEditModifiers);
});