define("discourse/plugins/swapd-support-tickets/discourse/controllers/user-activity-report", ["exports", "discourse-common/utils/decorators", "discourse/lib/ajax", "@ember/controller", "discourse/plugins/swapd-support-tickets/discourse/components/modal/rank-edit-modifiers", "@ember/application", "@glimmer/tracking", "@ember/object"], function (_exports, _decorators, _ajax, _controller, _rankEditModifiers, _application, _tracking, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let UserActivityReportController = _exports.default = (_dec = (0, _decorators.default)("model.sales"), _dec2 = (0, _decorators.observes)("model.is_public"), _dec3 = (0, _decorators.default)("model.user.id", "currentUser.id", "model.user.username"), (_class = class UserActivityReportController extends _controller.default {
    updateSales(v) {
      (0, _object.set)(this.model, 'sales_modifier', v);
    }
    updatePurchases(v) {
      (0, _object.set)(this.model, 'purchases_modifier', v);
    }
    fees(sales) {
      return Math.floor((sales || 0) / 100 * 8.5);
    }
    _updatePrivacy() {
      if (!this.model.can_edit_privacy) {
        return;
      }
      (0, _ajax.ajax)(`/report/u/${this.model.user.id}/privacy`, {
        type: "POST",
        data: {
          is_public: this.model.is_public
        }
      });
    }
    who(id1, id2, username) {
      if (id1 == id2) {
        return "you";
      }
      return username;
    }
    editModifiers() {
      let modal = (0, _application.getOwner)(this).lookup("service:modal");
      const result = modal.show(_rankEditModifiers.default, {
        model: {
          updateSales: v => this.updateSales(v),
          updatePurchases: v => this.updatePurchases(v),
          userId: this.model.user.id,
          sales: this.model.sales_modifier,
          purchases: this.model.purchases_modifier
        }
      });
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "fees", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "fees"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_updatePrivacy", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "_updatePrivacy"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "who", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "who"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "editModifiers", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "editModifiers"), _class.prototype)), _class));
});