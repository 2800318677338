define("discourse/plugins/swapd-support-tickets/discourse/initializers/add-support-tickets", ["exports", "discourse/lib/plugin-api", "discourse/models/topic", "discourse/lib/url", "discourse/components/text-field"], function (_exports, _pluginApi, _topic, _url, _textField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initializeSupportTickets(api) {
    _topic.default.reopen({
      createTicket: function (topic) {
        const dialog = api.container.lookup("service:dialog");
        dialog.confirm({
          cancelButtonClass: "btn-large",
          confirmButtonClass: "btn-large btn-primary",
          message: I18n.t("support_tickets.start_transaction_confirm"),
          didConfirm: () => {
            const opts = {
              afterRouteComplete() {
                const start = _url.default.controllerFor("start");
                start.set("topic", topic);
              }
            };
            _url.default.routeTo("/start", opts);
          }
        });
      }
    });
    _textField.default.reopen({
      attributeBindings: ["autocorrect", "autocapitalize", "autofocus", "maxLength", "dir", "autocomplete"]
    });
    api.modifyClass("component:topic-list-item", {
      pluginId: "swapd-support-tickets",
      classNameBindings: ["topic.support_ticket_created", "topic.closed", "topic.ticket_tag"]
    });
    api.decorateWidget("post-contents:after-cooked", dec => {
      const post = dec.getModel();
      if (!post.get("firstPost")) return;
      const status = post.get("start_checkout_status");
      if (status === "waiting") {
        const opts = {
          topic_id: post.topic_id
        };
        return dec.attach("ticket-accept-deny", opts);
      }
    });
  }
  var _default = _exports.default = {
    name: "add-support-tickets",
    initialize(container) {
      const siteSettings = container.lookup("site-settings:main");
      const currentUser = container.lookup("current-user:main");
      if (siteSettings.support_tickets_enabled) {
        (0, _pluginApi.withPluginApi)("0.5", initializeSupportTickets);
      }
    }
  };
});