define("discourse/plugins/swapd-support-tickets/discourse/components/step-three", ["exports", "discourse-common/utils/decorators", "@ember/utils", "@ember/component", "@ember/template"], function (_exports, _decorators, _utils, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.default)("model.seller_or_buyer"), _dec2 = (0, _decorators.default)("model.price"), (_obj = {
    title: I18n.t("support_tickets.start.title.steps.three.main"),
    subTitle(seller_or_buyer) {
      const target = seller_or_buyer == "seller" ? "buyer" : "seller";
      return (0, _template.htmlSafe)(I18n.t("support_tickets.start.title.steps.three.sub", {
        target
      }));
    },
    nextDisabled(price) {
      if (isNaN(price) || (0, _utils.isBlank)(price) || parseFloat(price) <= 0) return true;
      return false;
    },
    actions: {
      next() {
        if (this.get("nextDisabled")) return;
        this.set("step", 4);
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "subTitle", [_dec], Object.getOwnPropertyDescriptor(_obj, "subTitle"), _obj), _applyDecoratedDescriptor(_obj, "nextDisabled", [_dec2], Object.getOwnPropertyDescriptor(_obj, "nextDisabled"), _obj)), _obj)));
});