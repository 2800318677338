define("discourse/plugins/swapd-support-tickets/discourse/components/ticket-status", ["exports", "discourse-common/utils/decorators", "@ember/component", "I18n"], function (_exports, _decorators, _component, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.getTagList = getTagList;
  var _dec, _dec2, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let tagListObj;
  function getTagList() {
    if (tagListObj) return tagListObj;
    const tagList = JSON.parse(_I18n.default.t("support_tickets.tag_list")).map(t => {
      t.tag = t.tag.toLowerCase();
      return t;
    });
    tagListObj = tagList;
    return tagListObj;
  }
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.default)("topic.isPrivateMessage", "topic.support_ticket_created", "topic.closed", "topic.ticket_tag"), _dec2 = (0, _decorators.default)("shouldDisplay", "topic.ticket_tag"), (_obj = {
    shouldDisplay(isPrivateMessage, ticketCreated, closed, tag) {
      return isPrivateMessage && ticketCreated && !closed && tag;
    },
    selectedTag(shouldDisplay, tag) {
      if (!shouldDisplay) return;
      const tagList = getTagList();
      return tagList.findBy("tag", tag);
    }
  }, (_applyDecoratedDescriptor(_obj, "shouldDisplay", [_dec], Object.getOwnPropertyDescriptor(_obj, "shouldDisplay"), _obj), _applyDecoratedDescriptor(_obj, "selectedTag", [_dec2], Object.getOwnPropertyDescriptor(_obj, "selectedTag"), _obj)), _obj)));
});