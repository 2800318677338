define("discourse/plugins/swapd-support-tickets/discourse/templates/connectors/user-messages-nav/tickets", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if (canSeeTickets model)}}
    <LinkTo @model="userPrivateMessages.tickets">
      {{d-icon "shopping-cart"}}
      {{i18n "user.messages.tickets"}}
    </LinkTo>
  {{/if}}
  
  */
  {
    "id": "Eu5boak8",
    "block": "[[[41,[28,[37,1],[[30,0,[\"model\"]]],null],[[[1,\"  \"],[8,[39,2],null,[[\"@model\"],[\"userPrivateMessages.tickets\"]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,3],[\"shopping-cart\"],null]],[1,\"\\n    \"],[1,[28,[35,4],[\"user.messages.tickets\"],null]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,[28,[32,0],[\"[[\\\"The `model` property path was used in the `discourse/plugins/swapd-support-tickets/discourse/templates/connectors/user-messages-nav/tickets.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.model}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"if\",\"canSeeTickets\",\"link-to\",\"d-icon\",\"i18n\"]]",
    "moduleName": "discourse/plugins/swapd-support-tickets/discourse/templates/connectors/user-messages-nav/tickets.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});