define("discourse/plugins/swapd-support-tickets/discourse/templates/connectors/user-main-nav/xswapd-rank", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if site.mobileView}}
    {{#if model.swapd_rank_can_see}}
      <LinkTo @route="userActivity.report">
        <img class="sr__trophy" src="/plugins/swapd-support-tickets/trophy.png">
        <span>{{i18n "support_tickets.user_report.report"}}</span>
      </LinkTo>
    {{/if}}
  {{/if}}
  
  */
  {
    "id": "WpqMKGni",
    "block": "[[[41,[30,0,[\"site\",\"mobileView\"]],[[[41,[30,0,[\"model\",\"swapd_rank_can_see\"]],[[[1,\"    \"],[8,[39,1],null,[[\"@route\"],[\"userActivity.report\"]],[[\"default\"],[[[[1,\"\\n      \"],[10,\"img\"],[14,0,\"sr__trophy\"],[14,\"src\",\"/plugins/swapd-support-tickets/trophy.png\"],[12],[13],[1,\"\\n      \"],[10,1],[12],[1,[28,[35,2],[\"support_tickets.user_report.report\"],null]],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],null]],[]],null],[1,[28,[32,0],[\"[[\\\"The `site` property path was used in the `discourse/plugins/swapd-support-tickets/discourse/templates/connectors/user-main-nav/xswapd-rank.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.site}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `model` property path was used in the `discourse/plugins/swapd-support-tickets/discourse/templates/connectors/user-main-nav/xswapd-rank.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.model}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"if\",\"link-to\",\"i18n\"]]",
    "moduleName": "discourse/plugins/swapd-support-tickets/discourse/templates/connectors/user-main-nav/xswapd-rank.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});