define("discourse/plugins/swapd-support-tickets/discourse/components/open-tickets-count", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const CHANNEL = "/support-tickets/open-tickets-count";
  var _default = _exports.default = _component.default.extend({
    didInsertElement() {
      this._super(...arguments);
      this.messageBus.subscribe(CHANNEL, data => {
        if (data.user_id == this.currentUser.get("id")) {
          this.currentUser.set("open_tickets_count", data.count);
        }
      });
    },
    willDestroyElement() {
      this.messageBus.unsubscribe(CHANNEL);
      this._super(...arguments);
    }
  });
});