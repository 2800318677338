define("discourse/plugins/swapd-support-tickets/discourse/components/step-two", ["exports", "discourse-common/utils/decorators", "discourse/plugins/swapd-support-tickets/discourse/lib/support-ticket-constants", "discourse/lib/ajax", "discourse/lib/ajax-error", "@ember/utils", "@ember/component", "@ember/application", "@ember/template"], function (_exports, _decorators, _supportTicketConstants, _ajax, _ajaxError, _utils, _component, _application, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const BASE_I18N = "support_tickets.start.steps.two.";
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.default)("model.type", "loading"), _dec2 = (0, _decorators.default)("model.seller_or_buyer", "model.type"), (_obj = {
    options: _supportTicketConstants.TYPES,
    loading: false,
    title: (0, _template.htmlSafe)(I18n.t(BASE_I18N + "title")),
    helpMsg: (0, _template.htmlSafe)(I18n.t(BASE_I18N + "help_msg")),
    nextDisabled(type, loading) {
      if (loading) return true;
      if ((0, _utils.isBlank)(type)) return true;
      return false;
    },
    isShowAlert(sellerOrBuyer, type) {
      if (sellerOrBuyer === "buyer" && type === "service") {
        return true;
      }
      return false;
    },
    actions: {
      setValue(val) {
        this.set("model.type", val.toLowerCase());
      },
      next() {
        if (this.get("nextDisabled")) return;
        if (this.get("isShowAlert")) {
          this.set("loading", true);
          (0, _ajax.ajax)("/start/buyer_service", {
            type: "POST",
            data: {
              username: this.get("model.target_username")
            }
          }).then(() => {
            const msg = I18n.t(BASE_I18N + "alert");
            const dialog = (0, _application.getOwner)(this).lookup("service:dialog");
            dialog.alert({
              message: msg
            });
          }).catch(_ajaxError.popupAjaxError).finally(() => this.set("loading", false));
        } else {
          this.set("step", 3);
        }
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "nextDisabled", [_dec], Object.getOwnPropertyDescriptor(_obj, "nextDisabled"), _obj), _applyDecoratedDescriptor(_obj, "isShowAlert", [_dec2], Object.getOwnPropertyDescriptor(_obj, "isShowAlert"), _obj)), _obj)));
});