define("discourse/plugins/swapd-support-tickets/discourse/components/step-four", ["exports", "discourse-common/utils/decorators", "discourse-common/lib/icon-library", "discourse/lib/ajax", "discourse/lib/url", "@ember/string", "@ember/component", "@ember/template"], function (_exports, _decorators, _iconLibrary, _ajax, _url, _string, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.default)("model.seller_or_buyer"), _dec2 = (0, _decorators.default)("loading"), (_obj = {
    title: (0, _template.htmlSafe)(I18n.t("support_tickets.start.title.steps.four.main")),
    subTitle(seller_or_buyer) {
      const target = seller_or_buyer == "seller" ? "buyer" : "seller";
      return (0, _template.htmlSafe)(I18n.t("support_tickets.start.title.steps.four.sub", {
        target
      }));
    },
    nextDisabled(loading) {
      if (loading) return true;
      return false;
    },
    actions: {
      next() {
        const title = (0, _template.htmlSafe)(I18n.t("support_tickets.start.checkout_title"));
        const tos = (0, _template.htmlSafe)(I18n.t("support_tickets.start.checkout_tos"));
        const alert = `<div class="alert alert-error hidden"></div>`;
        const message = `<h3>${title}</h3><div style="margin:1rem 0;font-size:90%">${tos}</div>${alert}`;
        const buttons = [{
          label: "CANCEL",
          class: "btn-default btn-large cancel-btn",
          callback: () => {
            if ($(".cancel-btn.disabled").length) return false;
          }
        }, {
          label: (0, _iconLibrary.iconHTML)("shopping-cart") + I18n.t("support_tickets.start.checkout"),
          class: "btn-danger btn-large ok-btn btn-icon-text",
          callback: () => {
            $(".start__bootbox .alert").addClass("hidden");
            const btn = $(".ok-btn");
            if (btn.hasClass("disabled")) {
              return false;
            }
            const showError = () => {
              $(".start__bootbox .alert").removeClass("hidden").text("An error has occured");
            };
            const btns = $(".start__bootbox .btn");
            btns.addClass("disabled");
            const data = {};
            (0, _string.w)("target_username seller_or_buyer property price comment").forEach(k => {
              data[k] = this.get(`model.${k}`);
            });
            (0, _ajax.ajax)("/start", {
              type: "POST",
              data: data
            }).then(result => {
              _url.default.routeTo(result.url);
              bootbox.hideAll();
            }).catch(showError).finally(() => btns.removeClass("disabled"));
            return false;
          }
        }];
        bootbox.dialog(message, buttons, {
          classes: "start__bootbox"
        });
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "subTitle", [_dec], Object.getOwnPropertyDescriptor(_obj, "subTitle"), _obj), _applyDecoratedDescriptor(_obj, "nextDisabled", [_dec2], Object.getOwnPropertyDescriptor(_obj, "nextDisabled"), _obj)), _obj)));
});