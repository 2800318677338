define("discourse/plugins/swapd-support-tickets/discourse/helpers/support-tickets", ["exports", "discourse-common/lib/helpers", "discourse/models/user", "@ember/utils"], function (_exports, _helpers, _user, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.canSeeTickets = canSeeTickets;
  _exports.default = void 0;
  var _default = _exports.default = (0, _helpers.registerHelper)("canSeeTickets", params => {
    return canSeeTickets(params[0]);
  });
  function canSeeTickets(user) {
    const currentUser = _user.default.current();
    const siteSettings = Discourse.__container__.lookup("site-settings:main");
    return siteSettings.support_tickets_enabled && currentUser && (currentUser.get("id") == user.get("id") || currentUser.can_view_tickets);
  }
  (0, _helpers.registerHelper)("eq", params => {
    return params[0] == params[1];
  });
  (0, _helpers.registerHelper)("lowercase", params => {
    return (params[0] || "").toLowerCase();
  });
  (0, _helpers.registerHelper)("isblank", params => {
    return (0, _utils.isBlank)(params[0] || "");
  });
  (0, _helpers.registerHelper)("swapd-amount", params => {
    return toMoney(params[0]);
  });
  (0, _helpers.registerHelper)("swapd-fees", params => {
    return toMoney((params[0] || 0) / 100 * 8.5);
  });
  (0, _helpers.registerHelper)("swapd-rank", params => {
    const i = params[0];
    if (!i) return;
    var j = i % 10,
      k = i % 100;
    if (j == 1 && k != 11) {
      return i + "st";
    }
    if (j == 2 && k != 12) {
      return i + "nd";
    }
    if (j == 3 && k != 13) {
      return i + "rd";
    }
    return i + "th";
  });
  function toMoney(amount) {
    return "$" + (amount || 0).toLocaleString();
  }
  (0, _helpers.registerHelper)("swapd-last-updated", params => {
    if (params[0]) {
      return moment(params[0] * 1000).toString();
    }
  });
});