define("discourse/plugins/swapd-support-tickets/discourse/templates/components/open-tickets-count", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if currentUser.open_tickets_count}}
    <div class="open-tickets-count-container">
      <LinkTo @route="userPrivateMessages.tickets">
        {{i18n "support_tickets.open_tickets" count=currentUser.open_tickets_count}}
      </LinkTo>
    </div>
  {{/if}}
  
  */
  {
    "id": "pLkMCUQU",
    "block": "[[[41,[30,0,[\"currentUser\",\"open_tickets_count\"]],[[[1,\"  \"],[10,0],[14,0,\"open-tickets-count-container\"],[12],[1,\"\\n    \"],[8,[39,1],null,[[\"@route\"],[\"userPrivateMessages.tickets\"]],[[\"default\"],[[[[1,\"\\n      \"],[1,[28,[35,2],[\"support_tickets.open_tickets\"],[[\"count\"],[[30,0,[\"currentUser\",\"open_tickets_count\"]]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[1,[28,[32,0],[\"[[\\\"The `currentUser` property path was used in the `discourse/plugins/swapd-support-tickets/discourse/templates/components/open-tickets-count.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.currentUser}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `currentUser` property path was used in the `discourse/plugins/swapd-support-tickets/discourse/templates/components/open-tickets-count.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.currentUser}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"if\",\"link-to\",\"i18n\"]]",
    "moduleName": "discourse/plugins/swapd-support-tickets/discourse/templates/components/open-tickets-count.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});