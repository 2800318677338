define("discourse/plugins/swapd-support-tickets/discourse/widgets/ticket-accept-deny", ["discourse/widgets/widget", "discourse/lib/ajax-error", "discourse/lib/ajax", "@ember/application", "discourse/plugins/swapd-support-tickets/discourse/components/modal/ticket-deny-reason"], function (_widget, _ajaxError, _ajax, _application, _ticketDenyReason) {
  "use strict";

  (0, _widget.createWidget)("ticket-accept-deny", {
    tagName: "div.ticket-accept-deny",
    buildKey: attrs => `ticket-accept-deny-${attrs.topic_id}`,
    defaultState() {
      return {
        loading: false
      };
    },
    accept() {
      this.state.loading = true;
      (0, _ajax.ajax)("/start/accept", {
        type: "POST",
        data: {
          id: this.attrs.topic_id
        }
      }).then(() => {
        location.reload();
      }).catch(_ajaxError.popupAjaxError).finally(() => this.state.loading = false);
    },
    deny() {
      let modal = (0, _application.getOwner)(this).lookup("service:modal");
      const result = modal.show(_ticketDenyReason.default, {
        model: {
          topic_id: this.attrs.topic_id
        }
      });
    },
    html(attrs, state) {
      const acceptBtn = this.attach("button", {
        disabled: state.loading,
        className: "btn-primary btn-large",
        action: "accept",
        label: "support_tickets.start.accept_or_deny.accept"
      });
      const denyBtn = this.attach("button", {
        disabled: state.loading,
        className: "btn-danger btn-large",
        action: "deny",
        label: "support_tickets.start.accept_or_deny.deny"
      });
      return [acceptBtn, denyBtn];
    }
  });
});