define("discourse/plugins/swapd-support-tickets/discourse/components/modal/ticket-deny-reason", ["exports", "@ember/component", "discourse/lib/ajax-error", "discourse/lib/ajax", "@ember/object", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _component, _ajaxError, _ajax, _object, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    class="ticket-deny-reason"
    id="ticket-deny-reason"
    @closeModal={{@closeModal}}
  >
    <:body>
      {{d-section pageClass="start"}}
        <p>{{{i18n "support_tickets.start.accept_or_deny.modal"}}}</p>
        {{d-editor
          value=reason
        }}
    </:body>
    <:footer>
      <DButton
        @action={{action "deny"}}
        @class="btn-danger btn-large"
        @label={{"support_tickets.start.send"}}
        @disabled={{this.loading}}
      />
    </:footer>
  </DModal>
  */
  {
    "id": "zN+fekn9",
    "block": "[[[8,[39,0],[[24,0,\"ticket-deny-reason\"],[24,1,\"ticket-deny-reason\"]],[[\"@closeModal\"],[[30,1]]],[[\"body\",\"footer\"],[[[[1,\"\\n    \"],[1,[28,[35,1],null,[[\"pageClass\"],[\"start\"]]]],[1,\"\\n      \"],[10,2],[12],[2,[28,[37,2],[\"support_tickets.start.accept_or_deny.modal\"],null]],[13],[1,\"\\n      \"],[1,[28,[35,3],null,[[\"value\"],[[30,0,[\"reason\"]]]]]],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,4],null,[[\"@action\",\"@class\",\"@label\",\"@disabled\"],[[28,[37,5],[[30,0],\"deny\"],null],\"btn-danger btn-large\",\"support_tickets.start.send\",[30,0,[\"loading\"]]]],null],[1,\"\\n  \"]],[]]]]],[1,[28,[32,0],[\"[[\\\"The `reason` property path was used in the `discourse/plugins/swapd-support-tickets/discourse/components/modal/ticket-deny-reason.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.reason}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[\"@closeModal\"],false,[\"d-modal\",\"d-section\",\"i18n\",\"d-editor\",\"d-button\",\"action\"]]",
    "moduleName": "discourse/plugins/swapd-support-tickets/discourse/components/modal/ticket-deny-reason.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
  let TicketDenyReason = _exports.default = (_class = class TicketDenyReason extends _component.default {
    deny() {
      this.set("loading", true);
      (0, _ajax.ajax)("/start/deny", {
        type: "POST",
        data: {
          id: this.model.topic_id,
          reason: this.get("reason")
        }
      }).then(() => {
        location.reload();
      }).catch(_ajaxError.popupAjaxError).finally(() => {
        this.set("loading", false);
        this.set("reason", null);
        this.closeModal();
      });
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "deny", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "deny"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, TicketDenyReason);
});