define("discourse/plugins/swapd-support-tickets/discourse/lib/support-ticket-helper", ["exports", "discourse/lib/url", "discourse/lib/ajax-error", "discourse/lib/ajax", "discourse-common/lib/get-owner", "@ember/template"], function (_exports, _url, _ajaxError, _ajax, _getOwner, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isShowLimit = isShowLimit;
  function isShowLimit(price, paymentType, vipLevel, category, proceed) {
    const dialog = (0, _getOwner.getOwnerWithFallback)(this).lookup("service:dialog");
    if (category == "trades") {
      return false;
    }
    const i18n = path => {
      return I18n.t(`support_tickets.start.limit.${path}`);
    };
    const limitDialog = msg => {
      dialog.confirm({
        message: (0, _template.htmlSafe)(msg),
        confirmButtonLabel: "support_tickets.start.limit.fee_calculator",
        confirmButtonClass: "btn-large",
        cancelButtonLabel: "support_tickets.start.limit.go_back",
        cancelButtonClass: "btn-primary btn-large",
        didConfirm: () => {
          window.open("/fees", "_blank");
        },
        didCancel: () => {
          return;
        }
      });
    };
    if (paymentType == "bank wire") {
      if (price > 20000 && vipLevel == 4) {
        limitDialog(i18n("bankwire_limit_partner"));
        return true;
      }
      if (price > 10000 && vipLevel < 4) {
        limitDialog(i18n("bankwire_limit"));
        return true;
      }
    }
    if (paymentType == "credit card") {
      var limits = [0, 1000, 2000, 2000, 2500];
      if (price > limits[vipLevel]) {
        limitDialog(I18n.t("support_tickets.start.limit.creditcard_limit", {
          amount: limits[vipLevel]
        }));
        return true;
      }
    }
    return false;
  }
});