define("discourse/plugins/swapd-support-tickets/discourse/routes/user-private-messages-tickets", ["exports", "discourse/plugins/swapd-support-tickets/discourse/helpers/support-tickets", "discourse/routes/build-private-messages-route"], function (_exports, _supportTickets, _buildPrivateMessagesRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const TicketsRoute = (0, _buildPrivateMessagesRoute.default)("tickets", "tickets");
  TicketsRoute.reopen({
    activate() {
      const user = this.modelFor("user");
      if (!(0, _supportTickets.canSeeTickets)(user)) {
        this.transitionTo("user.index");
      }
    }
  });
  var _default = _exports.default = TicketsRoute;
});