define("discourse/plugins/swapd-support-tickets/discourse/connectors/after-topic-footer-buttons/next-prev-ticket", ["exports", "discourse/lib/url"], function (_exports, _url) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    // setupComponent({ topic }, component) {
    //   component.set("topic", topic);
    // },

    actions: {
      nextTicket(tk) {
        // const tk = this.get("topic.next_ticket")

        if (!tk) return;
        _url.default.routeTo(tk);
      },
      prevTicket(tk) {
        // const tk = this.get("topic.prev_ticket")

        if (!tk) return;
        _url.default.routeTo(tk);
      }
    }
  };
});