define("discourse/plugins/swapd-support-tickets/discourse/connectors/after-topic-footer-buttons/ticket-tag-selector-conn", ["exports", "discourse/plugins/swapd-support-tickets/discourse/components/ticket-status", "@ember/runloop"], function (_exports, _ticketStatus, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function getTopicCtrl() {
    return Discourse.__container__.lookup("controller:topic");
  }
  var _default = _exports.default = {
    setupComponent(args, component) {
      const tag = args.topic.ticket_tag;
      const tags = (0, _ticketStatus.getTagList)().mapBy("tag");
      const options = ["none"].concat(tags);
      const selectedTag = options.includes(tag) ? tag : "none";
      component.setProperties({
        options,
        selectedTag
      });
    },
    actions: {
      setTicketTag(tag) {
        $("body").addClass("admin-updating-tag");
        this.set("selectedTag", tag);
        const val = tag === "none" ? [] : [tag];
        const topicCtrl = getTopicCtrl();
        topicCtrl.set("editingTopic", true);
        topicCtrl.send("topicTagsChanged", val);
        topicCtrl.send("finishedEditingTopic");
        (0, _runloop.later)(this, () => {
          $("body").removeClass("admin-updating-tag");
        }, 3000);
      }
    }
  };
});