define("discourse/plugins/swapd-support-tickets/discourse/start-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default() {
    this.route("start", {
      resetNamespace: true,
      path: "/start"
    });
    this.route("report", {
      resetNamespace: true,
      path: "/report"
    });
  }
  ;
});