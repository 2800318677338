define("discourse/plugins/swapd-support-tickets/discourse/lib/support-ticket-constants", ["exports", "@ember/string"], function (_exports, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.TYPES = _exports.TEXT = _exports.TERM = _exports.STEPS = _exports.SEL = _exports.COMP = void 0;
  _exports.modelKeys = modelKeys;
  const COMP = _exports.COMP = "composer";
  const TEXT = _exports.TEXT = "text";
  const SEL = _exports.SEL = "select";
  const TERM = _exports.TERM = "terms";
  const TYPES = _exports.TYPES = ["Service", "Item"];
  const PAYMENTS = [{
    value: "Paypal",
    display: "support_tickets.start.steps.choices.payment_method.paypal"
  }, {
    value: "Bank Wire",
    display: "support_tickets.start.steps.choices.payment_method.bank_wire"
  }, {
    value: "Credit Card",
    display: "support_tickets.start.steps.choices.payment_method.credit_card"
  }, {
    value: "BitCoin",
    display: "support_tickets.start.steps.choices.payment_method.bitcoin"
  }, {
    value: "Ethereum",
    display: "support_tickets.start.steps.choices.payment_method.ethereum"
  }, {
    value: "USDT TRC20",
    display: "support_tickets.start.steps.choices.payment_method.usdt_trc20"
  }, {
    value: "USDT ERC20",
    display: "support_tickets.start.steps.choices.payment_method.usdt_erc20"
  }, {
    value: "USDC TRC20",
    display: "support_tickets.start.steps.choices.payment_method.usdc_trc20"
  }, {
    value: "USDC ERC20",
    display: "support_tickets.start.steps.choices.payment_method.usdc_erc20"
  }, {
    value: "TrustAp",
    display: "support_tickets.start.steps.choices.payment_method.trustap"
  }];
  const BOOLS = [{
    value: "Yes",
    display: "support_tickets.start.steps.choices.bools.option_yes"
  }, {
    value: "No",
    display: "support_tickets.start.steps.choices.bools.option_no"
  }, {
    value: "Does not apply",
    display: "support_tickets.start.steps.choices.bools.option_na"
  }];
  const STEPS = _exports.STEPS = {
    other: {
      about: {
        t: COMP
      },
      comments: {
        t: COMP,
        opt: true
      },
      terms: {
        t: TERM
      }
    },
    seller_service: {
      about: {
        t: COMP
      },
      delivery_time: {
        t: COMP
      },
      success_rate: {
        t: COMP
      },
      payment_method: {
        t: SEL,
        o: PAYMENTS
      },
      price: {
        t: TEXT
      },
      comments: {
        t: COMP,
        opt: true
      },
      terms: {
        t: TERM
      }
    },
    normal: {
      url_or_handle: {
        t: COMP
      },
      payment_method: {
        t: SEL,
        o: PAYMENTS
      },
      price: {
        t: TEXT
      },
      oge: {
        t: SEL,
        o: BOOLS
      },
      comments: {
        t: COMP,
        opt: true
      },
      terms: {
        t: TERM
      }
    }
  };
  function modelKeys() {
    const keys = (0, _string.w)("starter email target_email target_username seller_or_buyer target_vip_level target_trustap_full_user allowed_payment_methods type");
    Object.keys(STEPS).forEach(step => {
      keys.push(...Object.keys(STEPS[step]));
    });
    return keys;
  }
});